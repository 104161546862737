// ***************
// modal

@media screen and (max-width: @screen-xs-max) { // < 768px
	.modal {
		.m-window {
			top: 0;
			width: 100%;
			height: 100%;
			margin-bottom: 0;
			border: 0 none;

			.m-content {
				background-color: #fff;
			}
		}
	}
	.modal-msg {
		border: 0 none;
	}
	body.modal-bob-open .body-content {
		display: none;
	}
}

// *****
// ios

.ios {
  .modal {
  	position: absolute;
  	.m-background {
  		display: none;
  		position: absolute;
  	}
    .m-window {
      top: 0;
      left: 0;
      width: 100%;
			height: 100%;
      margin-left: 0;
			margin-bottom: 0;
			border: 0 none;
      border-radius: 0;

      .m-content {
				background-color: #fff;
      	// width: 540px;
      	// margin: 0 auto;
      }
    }
  }
  .modal-msg {
    top: 0;
    left: 0;
    width: 100%;
    margin-left: 0;
    border-radius: 0;
		border: 0 none;
    .msg-content {
      border-radius: 0;
    }
  }
	body.modal-bob-open .body-content {
		display: none;
	}
}
@media screen and (min-width: @screen-sm-min) { // >= 768px
	.ios {
	  .modal {
	    .m-window {
	      .m-content {
	      	width: 540px;
	      	margin: 0 auto;
	      }
	    }
	  }
	}
}

// ***************
