// passage

// anchor helper
.passage-anchor {
	position: relative;
	top: -100px;
}

// ********************

.passage {
  margin-top: 50px;
  margin-bottom: 100px;

  img {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}
