// jumbotron
// ---------

.jumbotron {
	// height: 300px;
	background-color: @brand-hero;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @media screen and (min-width: @screen-sm-min) {
    h1 {
      font-size: (@font-size-base * 3.5);
    }
  }
	@media screen and (max-width: @screen-phone) {
  	p {
  		font-size: @font-size-base;
  	}
	}
}